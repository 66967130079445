import { Form, Input, InputNumber, message, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../hooks/useApi";
import PointsService from "../../../services/api/PointsService";
import { getAllUsers } from "../../../store/actions/usersActions";

const { Option } = Select;

const CreatePointModal = ({
  userId,
  onCancel,
  successCallback = () => null,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [createLoading, setCreateLoading] = useState(false);

  const { data: usersData, loading: userLoading } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const { loading: namesLoading, data: apiData } = useApi(
    PointsService.getAllPointNames,
    {
      data: {},
    }
  );
  const pointNames = apiData.data;

  const usersOptions = usersData.map((user) => (
    <Option value={user.id} key={`user-${user.email}`}>
      {user.email}
    </Option>
  ));

  const pointNamesOptions = [...Object.keys(pointNames), "Add new"].map(
    (name) => (
      <Option value={name} key={`option-${name}`}>
        {name}
      </Option>
    )
  );

  const totalLoading = namesLoading || userLoading;

  const createNewPoint = async (values) => {
    const { action: originalAction, actionOtherName, point } = values;
    const action =
      originalAction == "Add new" ? actionOtherName : originalAction;

    try {
      setCreateLoading(true);
      await PointsService.createPoint({ userId, action, point });

      setCreateLoading(false);

      message.success("Created.");
      successCallback();
    } catch (error) {}
  };

  return (
    <Modal
      visible={userId}
      title="Edit User"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => form.validateFields().then(createNewPoint)}
      confirmLoading={createLoading}
    >
      <Spin spinning={totalLoading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item label="Email: " name="userId">
            <span className="ant-form-text">
              {usersData.find((user) => user.id === userId)?.email}
            </span>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.action !== currentValues.action
            }
          >
            {({ setFieldsValue }) => (
              <Form.Item
                name="action"
                label="Action"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "100%" }}
                  loading={namesLoading}
                  onSelect={(value) =>
                    setFieldsValue({ point: apiData?.data[value]?.point || 0 })
                  }
                  showSearch
                >
                  {pointNamesOptions}
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.action !== currentValues.action
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("action") === "Add new" ? (
                <Form.Item
                  name="actionOtherName"
                  label="Action Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            name="point"
            label="Point"
            rules={[{ required: true, type: "number" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreatePointModal;
