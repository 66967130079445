import { indexOf } from "lodash";
import UsersService from "../../services/api/UsersService";
import {
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
} from "./types";
const ranksConstant = [
  'White',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Indigo',
  'Violet',
  'Black',
];

export const getAllUsers = () => async (dispatch) => {
  dispatch({ type: GET_ALL_USERS_REQUEST });

  try {
    const res = await UsersService.getAllUsers();
    const { users } = res.data.data;

    users.forEach(function callback(user, index)  {
      const rankNumber = ranksConstant.indexOf(user.rank) + 1;
      const credit = (user.points / 100) * (rankNumber * 1);
      Object.assign(user,{ credit: credit})
    });


    users.forEach(function callback(user, index)  {
      Object.assign(user,{ tableId: index+1})
    });
  
    dispatch({ type: GET_ALL_USERS_SUCCESS, payload: { users } });
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_FAIL });
  }
};
