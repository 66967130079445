import request from "../../shared/lib/api";

const getPoint = (id) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/points/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getAllPoints = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/allPoints`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getAllPointNames = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/allPointsNames`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const createPoint = ({ userId, action, point }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/points`,
    method: "POST",
    data: { userId, action, point },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const editPoint = (id, { userId, action, point }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/points/${id}`,
    method: "PATCH",
    data: { userId, action, point },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const PointsService = {
  getPoint,
  getAllPoints,
  getAllPointNames,
  createPoint,
  editPoint,
};

export default PointsService;
