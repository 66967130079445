import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const ProtectedRoute = ({ component: Component, children, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLoggedIn ? (
          <Component {...props} {...rest} /> || children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { form: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
