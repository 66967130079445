import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Button, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/navigation/page-header/PageHeader";
import { getAllPoints } from "../../../store/actions/pointsActions";
import { getAllUsers } from "../../../store/actions/usersActions";
import CreatePointModal from "../modals/CreatePointModal";
import EditPointModal from "../modals/EditPointModal";
import { useParams } from "react-router-dom";

const PointListPage = () => {
  const dispatch = useDispatch();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(undefined);
  const [editModalId, setEditModalId] = useState(undefined);
  const { userId } = useParams();

  const { data: users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const { data, loading } = useSelector((state) => state.points);
  const filteredData = data.filter((point) => point.userId === userId);
  
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (name) => {
        return <Tag color="blue">{name}</Tag>;
      },
    },
    {
      title: "Point",
      dataIndex: "point",
      render: (point) => {
        return (
          <Badge
            className="site-badge-count-109"
            count={point}
            style={{ backgroundColor: "#52c41a" }}
            overflowCount={100000000}
          />
        );
      },
    },
    {
      title : "Created At",
      dataIndex : "createdAt",
      render : (createdAt) =>{
        return (

          <Typography>{moment(createdAt).format("YYYY-MM-DD hh:mm:ss")}</Typography>
          
        )
      }
    },
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => {
        return (
          <Link to={`/users/${userId}`}>
            <Button type="primary" icon={<UserOutlined />} size="small">
              {users.find((user) => user.id === userId)?.email}
            </Button>
          </Link>
        );
      },
    },
    {
      title: "Operation",
      dataIndex: "id",
      key: "actions",
      render: (id) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => setEditModalId(id)}
              icon={<EditOutlined />}
              size="small"
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllPoints());
  }, [dispatch]);

  return (
    <div>
      <PageHeader
        title="All Points List"
        extra={[
          <Button type="primary" onClick={() => {setIsCreateModalOpen(userId)}}>
            Create New Point
          </Button>,
        ]}
      />
      <Table
        loading={loading}
        rowKey="id"
        dataSource={filteredData}
        columns={columns}
        bordered
        pagination={false}
      />

      <CreatePointModal
        userId={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(undefined)}
        successCallback={() => dispatch(getAllPoints())}
      />
      <EditPointModal
        id={editModalId}
        onCancel={() => setEditModalId(undefined)}
        successCallback={() => dispatch(getAllPoints())}
      />
    </div>
  );
};

export default PointListPage;
