import {
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
} from "../actions/types";

const initialState = {
  data: undefined,
  error: "",
  loading: false,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        data: payload.user,
        loading: false,
      };

    case GET_USER_FAIL:
      return {
        ...state,
        data: undefined,
        error: "An Error!",
        loading: false,
      };

    default:
      return state;
  }
};

export default userReducer;
