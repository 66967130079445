import { Checkbox, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ranks from "../../../shared/constants/ranks"
import {
  getUserById,
  updateUserById,
} from "../../../store/actions/userActions";

const { Option } = Select;


const DeleteUserModal = ({ id, onCancel,successCallback = () => null }) => {

    // console.log(id)
    if(id)
    {
        // console.log(id.email)

    }

    // const [visible, setVisible] = useState(false);

    // const showModal = () => {
    //   setVisible(true);
    // };
  
    // const hideModal = () => {
    //   setVisible(false);
    // };



  const updateUser = async (inputs) => {
    // try {
    //   await dispatch(updateUserById(id, inputs));
    //   await successCallback();
    // } catch (error) {
    //   console.log(error);
    // }
  };


                  
  return (
    <Modal
      visible={!!id}
      title="Edit User"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={()=> successCallback(id.id)}
    >
        <p>Are you sure you want to delete {id? id.email : ''}</p>

    </Modal>
  );
};

export default DeleteUserModal;
