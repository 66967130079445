import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.less";
import AppLayout from "./components/layout/app-layout";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import {
  AllUsersListPage,
  DashboardPage,
  LoadingPage,
  LoginPage,
  NotFoundPage,
  PointListPage,
  UserDetailsPage,
} from "./pages";
import { autoLoginUser } from "./store/actions/authActions";

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(autoLoginUser());
  }, [dispatch]);

  if (auth.loading) {
    return <LoadingPage />;
  }

  return (
    <div className="App">
      <AppLayout>
        <Switch>
          <ProtectedRoute path="/" component={DashboardPage} exact />

          <ProtectedRoute
            path="/users/list"
            component={AllUsersListPage}
            exact
          />
          <ProtectedRoute path="/users/:id" component={UserDetailsPage} exact />

          <ProtectedRoute
            path="/points/list/:userId"
            component={PointListPage}
            exact
          />

          <Route path="/login" component={LoginPage} exact />

          <Route component={NotFoundPage} />
        </Switch>
      </AppLayout>
    </div>
  );
};

export default App;
