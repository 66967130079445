import { PageHeader as AntdPageHeader } from "antd";
import { useHistory } from "react-router-dom";

const PageHeader = ({ title, subTitle, extra, showBackButton = true }) => {
  const history = useHistory();

  return (
    <AntdPageHeader
      onBack={showBackButton ? history.goBack : false}
      title={title}
      subTitle={subTitle}
      extra={extra}
    />
  );
};

export default PageHeader;
