import UsersService from "../../services/api/UsersService";
import { GET_USER_FAIL, GET_USER_REQUEST, GET_USER_SUCCESS } from "./types";

export const getUserById = (id) => async (dispatch) => {
  dispatch({ type: GET_USER_REQUEST });

  try {
    const res = await UsersService.getUser(id);
    const { user } = res.data.data;

    dispatch({ type: GET_USER_SUCCESS, payload: { user } });
  } catch (error) {
    dispatch({ type: GET_USER_FAIL });
  }
};

export const updateUserById = (id, inputs) => async (dispatch) => {
  dispatch({ type: GET_USER_REQUEST });

  try {
    const res = await UsersService.updateUser(id, inputs);
    console.log(res);
    const { user } = res.data.data;

    dispatch({ type: GET_USER_SUCCESS, payload: { user } });
  } catch (error) {
    dispatch({ type: GET_USER_FAIL });
  }
};
