export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTO_LOGIN_FAIL = "AUTO_LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_ALL_POINTS_REQUEST = "GET_ALL_POINTS_REQUEST";
export const GET_ALL_POINTS_SUCCESS = "GET_ALL_POINTS_SUCCESS";
export const GET_ALL_POINTS_FAIL = "GET_ALL_POINTS_FAIL";

export const GET_ALL_POINT_REQUEST = "GET_ALL_POINT_REQUEST";
export const GET_ALL_POINT_SUCCESS = "GET_ALL_POINT_SUCCESS";
export const GET_ALL_POINT_FAIL = "GET_ALL_POINT_FAIL";
