import { Checkbox, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ranks from "../../../shared/constants/ranks"
import {
  getUserById,
  updateUserById,
} from "../../../store/actions/userActions";

const { Option } = Select;


const EditUserModal = ({ id, onCancel, successCallback = () => null }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    form.resetFields();
    if (user.data) {
      form.setFieldsValue({ ...user.data });
    }
  }, [user, form]);

  const updateUser = async (inputs) => {
    try {
      await dispatch(updateUserById(id, inputs));
      await successCallback();
    } catch (error) {
      console.log(error);
    }
  };


                  
  return (
    <Modal
      visible={!!id}
      title="Edit User"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => form.validateFields().then(updateUser)}
    >
      <Spin spinning={user.loading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="oscarId"
            label="Oscar Id"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Row gutter={[20]}>
            <Col span={20}>
              <Form.Item name="rank" label="Rank" rules={[{ required: true }]}>
                <Select>

                  {ranks.map((rank) => (
                    <Option value={rank}> {rank} </Option>
                  ))}
              
                  
                </Select>
              </Form.Item>
            </Col>
            <Col span={208}>
              <Form.Item label=" " name="plus" valuePropName="checked">
                <Checkbox>Plus</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditUserModal;
