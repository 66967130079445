import {
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
} from "../actions/types";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        data: payload.users,
        loading: false,
      };

    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        data: [],
        error: "An Error!",
        loading: false,
      };

    default:
      return state;
  }
};

export default usersReducer;
