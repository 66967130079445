import request from "../../shared/lib/api";

const loginWithEmail = (userInput) =>
  request({
    url: `/auth/loginWithEmail`,
    method: "POST",
    data: userInput,
  });

const AuthService = {
  loginWithEmail,
};

export default AuthService;
