import { Form, Input, InputNumber, message, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../hooks/useApi";
import PointsService from "../../../services/api/PointsService";
import { getPointById } from "../../../store/actions/pointActions";
import { getAllUsers } from "../../../store/actions/usersActions";

const { Option } = Select;

const CreatePointModal = ({ id, onCancel, successCallback = () => null }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [createLoading, setCreateLoading] = useState(false);

  const { data: usersData, loading: userLoading } = useSelector(
    (state) => state.users
  );

  const { data: pointData, loading: pointLoading } = useSelector(
    (state) => state.point
  );

  const { loading: namesLoading, data: apiData } = useApi(
    PointsService.getAllPointNames,
    {
      data: {},
    }
  );
  const actionNames = apiData.data;

  useEffect(() => {
    if (id) dispatch(getPointById(id));

    dispatch(getAllUsers());
  }, [dispatch, id]);

  useEffect(() => {
    if (!(actionNames && pointData)) {
      return;
    }

    const { action: pointAction, point, userId } = pointData;

    let action;
    let actionOtherName;
    if (!Object.keys(actionNames).includes(pointAction)) {
      action = "OTHER";
      actionOtherName = pointAction;
    } else {
      action = pointAction;
    }

    console.log({ userId, point, action, actionOtherName });

    form.setFieldsValue({ userId, point, action, actionOtherName });
  }, [form, actionNames, pointData]);

  const usersOptions = usersData.map((user) => (
    <Option value={user.id} key={`user-${user.email}`}>
      {user.email}
    </Option>
  ));

  const pointNamesOptions = [...Object.keys(actionNames), "OTHER"].map(
    (name) => (
      <Option value={name} key={`option-${name}`}>
        {name}
      </Option>
    )
  );

  const totalLoading = namesLoading || userLoading || pointLoading;

  const updatePoint = async (values) => {
    const { userId, action: originalAction, actionOtherName, point } = values;
    const action =
      originalAction === "OTHER" ? actionOtherName : originalAction;

    try {
      setCreateLoading(true);
      await PointsService.editPoint(pointData.id, { userId, action, point });
      setCreateLoading(false);

      dispatch(getPointById(id));

      message.success("Updated.");
      successCallback();
    } catch (error) {}
  };

  return (
    <Modal
      visible={!!id}
      title="Edit Point"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => form.validateFields().then(updatePoint)}
      confirmLoading={createLoading}
    >
      <Spin spinning={totalLoading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="userId" label="User" rules={[{ required: true }]}>
            <Select
              style={{ width: "100%" }}
              loading={userLoading}
              filterOption={(inputValue, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {usersOptions}
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.action !== currentValues.action
            }
          >
            {({ setFieldsValue }) => (
              <Form.Item
                name="action"
                label="Action"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "100%" }}
                  loading={namesLoading}
                  onSelect={(value) =>
                    setFieldsValue({ point: apiData?.data[value]?.point || 0 })
                  }
                  showSearch
                >
                  {pointNamesOptions}
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.action !== currentValues.action
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("action") === "OTHER" ? (
                <Form.Item
                  name="actionOtherName"
                  label="Action Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            name="point"
            label="Point"
            rules={[{ required: true, type: "number" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreatePointModal;
