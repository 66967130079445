import PointsService from "../../services/api/PointsService";
import {
  GET_ALL_POINTS_REQUEST,
  GET_ALL_POINTS_SUCCESS,
  GET_ALL_POINTS_FAIL,
} from "./types";

export const getAllPoints = () => async (dispatch) => {
  dispatch({ type: GET_ALL_POINTS_REQUEST });

  try {
    const res = await PointsService.getAllPoints();
    const { points } = res.data.data;

    dispatch({ type: GET_ALL_POINTS_SUCCESS, payload: { points } });
  } catch (error) {
    dispatch({ type: GET_ALL_POINTS_FAIL });
  }
};
