import request from "../../shared/lib/api";

const getAllUsers = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/users`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getUser = (id) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const updateUser = (id, data) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return request({
    url: `/users/${id}`,
    method: "PATCH",
    data: { ...data },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const UsersService = {
  getAllUsers,
  getUser,
  updateUser,
};

export default UsersService;
