/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import { logoutUser } from "../../../store/actions/authActions";
import "./styles.less";

const { Sider } = Layout;
const { Content, Footer } = Layout;

const SideBar = () => {
  const location = useLocation();

  const items = [
    {
      to: "/",
      name: "Dashboard",
    },
    {
      to: "/users/list",
      name: "Users",
    },
  ];

  const menuItems = items.map((item) => {
    const { to, name } = item;
    if (!(to && name)) return null;

    return (
      <Menu.Item key={to}>
        <Link to={to}>{name}</Link>
      </Menu.Item>
    );
  });

  return (
    <Sider className="sidebar">
      <Row justify="center" style={{ paddingBottom: "20px" }}>
        <Space size={[0]} align="center" direction="vertical" wrap>
          <a href="https://heatwellbeing.com/">
            <img width="100px" src={Logo} className="logo" alt="logo" />
          </a>
        </Space>
      </Row>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        {menuItems}
      </Menu>
    </Sider>
  );
};

const TopMenu = ({ user }) => {
  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(logoutUser());
  };

  const menu = (
    <Menu>
      <Menu.Divider />
      <Menu.Item
        key="2"
        onClick={onLogoutClick}
        icon={<LogoutOutlined />}
        danger
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Menu
      mode="horizontal"
      style={{ padding: "20px 0", backgroundColor: "transparent" }}
    >
      <div style={{ marginLeft: "auto", marginRight: "20px" }}>
        <Space>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {user?.username} <DownOutlined />
            </a>
          </Dropdown>
          <Avatar
            shape="circle"
            size="large"
            src={user.avatarUrl || ""}
            icon={<UserOutlined />}
          />
        </Space>
      </div>
    </Menu>
  );
};

const AppLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Layout id="app-layout" style={{ minHeight: "100vh" }}>
      {auth.isLoggedIn ? <SideBar /> : null}
      <Layout className="site-layout">
        <Content style={{ margin: "16px" }}>
          {auth.isLoggedIn ? <TopMenu user={auth.user} /> : null}
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; Copyright 2022 HEAT Admin Dashboard v1.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
