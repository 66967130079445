import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoggedIn: Boolean(user),
  user: user || null,
  loading: false,
  error: "",
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        redirectTo: payload.redirectTo,
        loading: false,
      };

    case LOGIN_FAIL:
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: "401",
        loading: false,
      };

    case LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default userReducer;
