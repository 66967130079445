import { message } from "antd";
import ApiAuthService from "../../services/api/ApiAuthService";
import UserService from "../../services/api/UserService";
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "./types";

export const autoLoginUser = () => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const res = await UserService.getProfile();
    const { user } = res.data.data;

    dispatch({ type: LOGIN_SUCCESS, payload: { user } });
  } catch (error) {
    dispatch({ type: LOGOUT });
  }
};

export const loginUserWithEmail = (userInput) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await ApiAuthService.loginWithEmail(userInput);
    const { user } = res.data.data;

    if (user?.role.toLowerCase() === "admin" || user?.role.toLowerCase() === "editor") {
    }else{

      
      throw new Error("Access denied!");
    }

    localStorage.setItem("user", JSON.stringify(user));
    dispatch({ type: LOGIN_SUCCESS, payload: { user } });

    message.success("You have successfully logged in.");
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    const errMessage = error?.response?.data?.message || error.message;
    dispatch({ type: LOGIN_FAIL });
    message.error(errMessage);
  }
};

export const logoutUser = () => {
  localStorage.removeItem("user");

  return {
    type: LOGOUT,
  };
};
