import {
  GET_ALL_POINTS_REQUEST,
  GET_ALL_POINTS_SUCCESS,
  GET_ALL_POINTS_FAIL,
} from "../actions/types";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

const pointsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_POINTS_SUCCESS:
      return {
        ...state,
        data: payload.points,
        loading: false,
      };

    case GET_ALL_POINTS_FAIL:
      return {
        ...state,
        data: [],
        error: "An Error!",
        loading: false,
      };

    default:
      return state;
  }
};

export default pointsReducer;
