import { SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";

const DashboardPage = () => {
  return (
    <Result
      icon={<SmileOutlined />}
      title="Great, we have done all the operations!"
      //   extra={<Button type="primary">Next</Button>}
    />
  );
};

export default DashboardPage;
