import { Empty, Spin, Row, Descriptions, Tag, Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "../../../components/navigation/page-header/PageHeader";
import { getUserById } from "../../../store/actions/userActions";
import EditUserModal from "../modals/EditUserModal";

const UserDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [editModalId, setEditModalId] = useState(undefined);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id));
    }
  }, [id, dispatch]);

  let displayData = null;
  if (user.loading) {
    displayData = (
      <Row justify="center">
        <Spin size="large" />
      </Row>
    );
  } else if (!user.data) {
    displayData = <Empty />;
  } else {
    const { data } = user;
    displayData = (
      <Descriptions bordered>
        <Descriptions.Item label="Email" span={3}>
          {data.email}
        </Descriptions.Item>
        <Descriptions.Item label="Username">{data.username}</Descriptions.Item>
        <Descriptions.Item label="First Name">
          {data.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">{data.lastName}</Descriptions.Item>
        <Descriptions.Item label="Rank">
          {data.rank} {data.plus ? "+" : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Role" span={2}>
          <Tag color={data.role === "admin" ? "green" : undefined}>
            {data.role}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Other Details">
          Created At: {moment(data.createdAt).format("YYYY-MM-DD hh:mm:ss")}
          <br />
          UpdatedAt At: {moment(data.updatedAt).format("YYYY-MM-DD hh:mm:ss")}
          <br />
        </Descriptions.Item>
      </Descriptions>
    );
  }

  const updateUserButton = (
    <Button type="primary" onClick={() => setEditModalId(id)}>
      Edit this user
    </Button>
  );

  return (
    <div>
      <PageHeader title={`User Details`} extra={[updateUserButton]} />
      {displayData}

      <EditUserModal
        id={editModalId}
        onCancel={() => setEditModalId(undefined)}
      />
    </div>
  );
};

export default UserDetailsPage;
