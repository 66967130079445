import { combineReducers } from "redux";
import authReducer from "./authReducer";
import usersReducer from "./usersReducer";
import userReducer from "./userReducer";
import pointsReducer from "./pointsReducer";
import pointReducer from "./pointReducer";

export default combineReducers({
  auth: authReducer,

  users: usersReducer,
  user: userReducer,

  points: pointsReducer,
  point: pointReducer,
});
