import {
  GET_ALL_POINT_FAIL,
  GET_ALL_POINT_SUCCESS,
  GET_ALL_POINT_REQUEST,
} from "../actions/types";

const initialState = {
  data: undefined,
  error: "",
  loading: false,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_POINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_POINT_SUCCESS:
      return {
        ...state,
        data: payload.point,
        loading: false,
      };

    case GET_ALL_POINT_FAIL:
      return {
        ...state,
        data: undefined,
        error: "An Error!",
        loading: false,
      };

    default:
      return state;
  }
};

export default userReducer;
