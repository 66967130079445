import PointsService from "../../services/api/PointsService";
import {
  GET_ALL_POINT_FAIL,
  GET_ALL_POINT_SUCCESS,
  GET_ALL_POINT_REQUEST,
} from "./types";

export const getPointById = (id) => async (dispatch) => {
  dispatch({ type: GET_ALL_POINT_REQUEST });

  try {
    const res = await PointsService.getPoint(id);
    const { point } = res.data.data;

    dispatch({ type: GET_ALL_POINT_SUCCESS, payload: { point } });
  } catch (error) {
    dispatch({ type: GET_ALL_POINT_FAIL });
  }
};
